var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Nome")]),_c('th',{staticClass:"text-left"},[_vm._v("Conta")]),_c('th',{staticClass:"text-left"},[_vm._v("Monitoramento")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.showGroups),expression:"$store.getters.showGroups"}],staticClass:"text-left"},[_vm._v("Grupo")]),_c('th',{staticClass:"text-center"},[_vm._v("Ações")])])]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.accountNumber))]),_c('td',[_vm._v(_vm._s(_vm.$store.getters.getMonitoramentoNameById(item.moniId)))]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.showGroups),expression:"$store.getters.showGroups"}]},[_vm._v(" "+_vm._s(_vm.$store.getters.getGroupNameById(item.groupId))+" ")]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('verCliente', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('del', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('viewCam', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cctv")])],1)]}}],null,true)},[_c('span',[_vm._v("Cameras")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('viewEvt', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-party-popper")])],1)]}}],null,true)},[_c('span',[_vm._v("Eventos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('viewAutomation', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-robot-industrial")])],1)]}}],null,true)},[_c('span',[_vm._v("Automações")])])],1)])}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }