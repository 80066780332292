<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ item.id ? "Editar" : "Cadastrar" }} Cliente
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <v-card-text>
            <v-form ref="formCliente">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome"
                    v-model="item.name"
                    :counter="255"
                    :rules="reqFieldMinLength(6)"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="$store.getters.getGroupSelect"
                    label="Grupo"
                    v-model="item.groupId"
                    auto-select-first
                    :rules="requiredField"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Numero da Conta"
                    hint="O numero que esta no software de monitoramento"
                    persistent-hint
                    v-model="item.accountNumber"
                    :counter="10"
                    :rules="reqFieldMinLength(2)"
                  ></v-text-field>
                </v-col>
                <v-col v-show="softwareId == 2">
                  <v-text-field
                    label="Codigo da Empresa"
                    hint="O mesmo valor que esta cadastrado no SIGMA (Somente SIGMA)"
                    persistent-hint
                    v-model="item.empresaId"
                    :counter="10"
                    :rules="softwareId == 2 ? requiredField : []"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    auto-select-first
                    :items="$store.getters.getMonitoramentosSelect"
                    label="Monitoramento"
                    hint="Selecione o monitoramento que recebera os eventos desta camera"
                    v-model="item.moniId"
                    persistent-hint
                    :rules="requiredField"
                    @change="getVmsId"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Usuário do software de video monitoramento"
                    v-model="item.vmsUser"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Senha do software de video monitoramento"
                    v-model="item.vmsPass"
                  ></v-text-field>
                  <!-- :rules="reqFieldMinLength(3)" -->
                </v-col>
                <v-col cols="auto" v-show="softwareVmsId == 1">
                  <v-card outlined>
                    <v-card-subtitle class="pb-1 pt-1">
                      <v-spacer> </v-spacer>
                      <span> Dguard </span>
                      <v-spacer> </v-spacer>
                    </v-card-subtitle>
                    <v-card-text class="pb-1">
                      <v-spacer> </v-spacer>
                      <ToolTipIcon
                        icon="mdi-test-tube"
                        text="Testar configuração"
                        top="true"
                        @click="test"
                      />
                      <ToolTipIcon
                        icon="mdi-account-cog"
                        text="Criar usuário no Dguard"
                        top="true"
                        @click="openDialogDguard"
                      />
                      <v-spacer> </v-spacer>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="item.description"
                    rows="3"
                    label="Descrição"
                    hint="Digite uma descrição se necessario"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="success" text @click="save"> Salvar </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <CreateUserDguard
      v-model="dialogDguard"
      :monit="item.moniId"
      :client="item"
      @userCreated="userCreated"
    />
  </v-dialog>
</template>

<script>
import {
  requiredField,
  reqFieldMinLength,
} from "../../../Utils/InputValidation";
import ToolTipIcon from "../../../components/ToolTipIcon";
import CreateUserDguard from "./CreateUserDguard.vue";
export default {
  name: "FormCliente",
  components: { ToolTipIcon, CreateUserDguard },
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogIP: false,
      dialogDguard: false,
      requiredField,
      reqFieldMinLength,
      softwareVmsId: 0,
      softwareId: 0,
    };
  },
  methods: {
    save() {
      if (!this.$refs.formCliente.validate()) {
        return;
      }
      this.$emit("save", this.item);
    },
    test() {
      if (!this.$refs.formCliente.validate()) {
        return;
      }
      this.$store.dispatch("saveCliente", this.item);
      this.$store.dispatch("verifyUserPassServerDguard", this.item.id);
    },
    openDialogDguard() {
      if (!this.$refs.formCliente.validate()) {
        return;
      }
      this.dialogDguard = true;
    },
    close() {
      this.$refs.formCliente.resetValidation();
      this.$emit("closeDialog");
    },
    getVmsId() {
      if (this.item.moniId) {
        let moni = this.$store.getters.getMonitoramentoById(this.item.moniId);
        this.softwareVmsId = moni.softwareVmsId;
        this.softwareId = moni.softwareId;
      }
    },
    userCreated(newUser){
      this.item.vmsUser = newUser.newUserName;
      this.item.vmsPass = newUser.newUserPass;
      this.dialogDguard = false;
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getVmsId();
        if(this.$store.getters.getGroups.length ==1){
          this.item.groupId = this.$store.getters.getGroups[0].id;
        }
      }
    },
  },
};
</script>

<style>
</style>