<template>
  <v-simple-table dark>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">ID</th>
          <th class="text-left">Nome</th>
          <th class="text-left">Conta</th>

          <th class="text-left">Monitoramento</th>

          <th class="text-left" v-show="$store.getters.showGroups">Grupo</th>
          <th class="text-center">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>

          <td>{{ item.accountNumber }}</td>

          <td>{{ $store.getters.getMonitoramentoNameById(item.moniId) }}</td>

          <td v-show="$store.getters.showGroups">
            {{ $store.getters.getGroupNameById(item.groupId) }}
          </td>
          <td class="text-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('verCliente', item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('edit', item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('del', item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('viewCam', item)"
                >
                  <v-icon>mdi-cctv</v-icon>
                </v-btn>
              </template>
              <span>Cameras</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('viewEvt', item)"
                >
                  <v-icon>mdi-party-popper</v-icon>
                </v-btn>
              </template>
              <span>Eventos</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('viewAutomation', item)"
                >
                  <v-icon>	mdi-robot-industrial</v-icon>
                </v-btn>
              </template>
              <span>Automações</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TableCliente",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    copyUrl(item) {
      navigator.clipboard.writeText(item.url);
      this.$store.dispatch("resetSnack");
      this.$store.dispatch(
        "showSuccessSnack",
        `URL da Conta ${item.accountNumber} copiada para area de transferencia`
      );
    },
  },
};
</script>

<style>
</style>