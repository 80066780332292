<template>
  <div>
    <v-card>
      <v-card-title>
        Clientes
        <v-spacer> </v-spacer>
        <v-btn text color="success" @click="showForm = !showForm">
          Adicionar Cliente
        </v-btn>
      </v-card-title>
    </v-card>
    <!-- TABELA -->
    <v-card class="mt-2">
      <TableCliente
        :items="getClientes"
        @edit="edit"
        @verCliente="openVerCliente"
        @del="del"
        @viewCam="viewCam"
        @viewEvt="viewEvt"
        @viewAutomation="viewAutomation"
      />
    </v-card>
    <!-- FORM -->
    <FormCliente
      :item="cliente"
      v-model="showForm"
      @closeDialog="closeForm"
      @save="save"
    />
    <VerCliente :item="cliente" v-model="verClienteSts" />
  </div>
</template>

<script>
import TableCliente from "./components/TableCliente.vue";
import FormCliente from "./components/FormCliente.vue";
import VerCliente from "./components/VerCliente.vue";
export default {
  name: "Clientes",
  components: {
    TableCliente,
    FormCliente,
    VerCliente,
  },
  data: () => {
    return {
      search: "",
      ip: "",
      tomcatPort: 8080,
      showForm: false,
      dialog: false,
      verClienteSts: false,
      cliente: {
        name: "",
        groupId: 0,
      },
    };
  },
  methods: {
    get() {
      this.$store.dispatch("loadClientes");
      this.$store.dispatch("loadGrupos");
      this.$store.dispatch("loadMonitoramentos");
    },
    closeDialoag() {
      this.cliente = {};
      this.dialog = false;
    },
    closeForm() {
      this.cliente = {};
      this.showForm = false;
    },
    openDialoag(cliente) {
      if (cliente) {
        this.cliente = cliente;
      }
      this.dialog = true;
    },
    openVerCliente(cliente) {
      if (cliente) {
        this.cliente = cliente;
      }
      this.verClienteSts = true;
    },
    save(cliente) {
      this.$store.dispatch("saveCliente", cliente);
      this.showForm = false;
    },
    del(item) {
      this.$store.dispatch("deletCliente", item);
    },
    edit(item) {
      this.showForm = true;
      this.cliente = item;
    },
    viewCam(item) {
      this.$router.push({
        path: "/cameras",
        params: {
          clientId: "kkk",
        },
        query: {
          clientId: item.id,
        },
      });
    },
    viewEvt(item) {
      this.$router.push({
        path: "/eventos",
        params: {
          clientId: "kkk",
        },
        query: {
          clientId: item.id,
        },
      });
    },
    viewAutomation(item) {
      this.$router.push({
        path: "/clienteAutomacao",
        query: {
          clientId: item.id,
        },
      });
    },
  },
  computed: {
    getClientes: {
      get() {
        return this.$store.getters.getClientes;
      },
      set() {},
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style>
</style>