<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title> Usuário DGUARD </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <!-- card do login -->
          <v-card-text>
            <v-card outlined :disabled="loginDguard.userToken.length > 0">
              <v-card-title>
                Usuário e senha com permissão de criar usuários no DGUARD
              </v-card-title>
              <v-card-text>
                <v-form ref="formLogin">
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Usuário"
                        v-model="userDguard.vmsUser"
                        :rules="reqFieldMinLength(3)"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Senha"
                        v-model="userDguard.vmsPass"
                        :rules="reqFieldMinLength(3)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="mt-2">
                      <v-btn @click="login" color="success">Logar</v-btn>
                    </v-col>
                  </v-row>
                  <v-card-title v-show="loginDguard.userToken.length > 0">
                    <v-spacer></v-spacer>
                    <span>Logado</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                </v-form>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <!-- card do grupo e server -->
        <v-card outlined :disabled="!loginDguard.userToken.length > 0" class="mt-2">
          <v-card-title> Grupo e usuário </v-card-title>
          <v-card-subtitle v-show="!loginDguard.userToken.length > 0">
            Faça o login para desbloquear estes campos
            </v-card-subtitle>
          <v-card-text>
            <v-form ref="formGPUS">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome do Grupo"
                    v-model="userModel.newGroupName"
                    :rules="reqFieldMinLength(6)"
                    :disabled="groupExists()"
                    :hint="groupExists() ? 'Grupo já existe' : ''"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="getSelectServer()"
                    label="Servidor"
                    v-model="userModel.serverGuid"
                    auto-select-first
                    :rules="requiredField"
                    :disabled="userExists() || groupExists()"
                    :hint="userExists() ? 'Usuário ou grupo já existe' : ''"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome do usuário"
                    v-model="userModel.newUserName"
                    :rules="reqFieldMinLength(6)"
                    :disabled="userExists()"
                    :hint="userExists() ? 'Usuário já existe' : ''"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Senha do usuário"
                    v-model="userModel.newUserPass"
                    :rules="reqFieldMinLength(6)"
                    :disabled="userExists()"
                    :hint="userExists() ? 'Usuário já existe' : ''"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-title v-show="userExists() || groupExists()">
                    <v-spacer></v-spacer>
                    <span>Usuário ou grupo já existe, faça a edição no DGUARD</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addUser" color="success" :disabled="userExists() || groupExists()"> Criar usuário </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  requiredField,
  reqFieldMinLength,
} from "../../../Utils/InputValidation";
export default {
  name: "CreateUserDguard",
  components: {},
  props: {
    client: {
      type: Object,
      required: true,
    },
    monit: {
      type: Number,
      required: true,
      default: 0,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogIP: false,
      requiredField,
      reqFieldMinLength,
      userDguard: {
        vmsPass: "",
        vmsUser: "",
      },
      loginDguard: {
        userToken: "",
        serverDate: "",
        serverTime: "",
        userGroupGuid: "",
        username: "",
      },
      groups: [],
      servers: [],
      users: [],
      userModel: {
        newGroupName: "",
        newUserName: "",
        newUserPass: "",
        serverGuid: "",
      },
    };
  },
  methods: {
    save() {
      if (!this.$refs.formLogin.validate()) return;
      this.$emit("save", this.item);
    },
    userExists() {
      return (
        this.users.includes((u) => u.name == this.userModel.newUserName)
      );
    },
    groupExists() {
      return (
        this.groups.includes((u) => u.name == this.userModel.newGroupName)
      );
    },
    close() {
      this.$emit("input", false);
    },
    login() {
      if (!this.$refs.formLogin.validate()) return;
      this.$http
        .post("/dguard/getAllData", {
          ...this.userDguard,
          monitoramentoId: this.monit,
        })
        .then((res) => {
          this.loginDguard = res.data.login;
          this.servers = res.data.servers;
          this.groups = res.data.userGroups;
          this.users = res.data.users;
        });
    },
    addUser() {
      if (!this.$refs.formGPUS.validate()) return;
      if (this.loginDguard.userToken.length == 0) return;
      if (this.monit == 0) return;
      // passando na validação e logado no dguard, grava o usuário
      this.$http
        .post("/dguard/addUser", {
          userToken: this.loginDguard.userToken,
          monitoramentoId: this.monit,
          ...this.userModel,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$store.dispatch("resetSnack");
            this.$store.dispatch(
              "showSuccessSnack",
              "Usuário criado com sucesso!"
            );
            this.$emit("userCreated", this.userModel);
          }
        });
    },
    getSelectGroup() {
      let sel = [];
      this.groups.forEach((element) => {
        sel.push({
          value: element.guid,
          text: `${element.name} - Nivel: ${element.level}`,
        });
      });
      return sel;
    },
    getSelectServer() {
      let sel = [];
      this.servers.forEach((element) => {
        sel.push({
          value: element.guid,
          text: `${element.name}`,
          disabled: element.hasChildren,
        });
      });
      return sel;
    },
  },
  watch: {
    value: function (val) {
      if (!val) {
        this.userDguard = {
          vmsPass: "",
          vmsUser: "",
        };
        this.loginDguard = {
          userToken: "",
          serverDate: "",
          serverTime: "",
          userGroupGuid: "",
          username: "",
        };
        this.groups = [];
        this.servers = [];
        this.group = {
          guid: "",
          name: "",
          level: 1,
          readOnly: false,
          readOnlyPermissions: false,
          isAdmin: false,
        };
        this.user = {
          guid: "",
          name: "",
          password: "",
          groupGuid: "",
          email: "",
          readOnly: true,
          readOnlyPassword: false,
        };
      } else {
        this.userModel.newUserPass = new Date().getTime().toString();
        this.userModel.newGroupName = `${
          this.client.accountNumber
        }${this.client.name.split(" ").join("")}`;

        this.userModel.newUserName = `${
          this.client.accountNumber
        }${this.client.name.split(" ").join("")}`.toLowerCase();
      }
    },
  },
};
</script>

<style>
</style>