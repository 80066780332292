<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Ver Cliente</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-black">Indice:</td>
                  <td>{{ item.id }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Nome:</td>
                  <td>{{ item.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Número da Conta:</td>
                  <td>{{ item.accountNumber }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Codigo da empresa:</td>
                  <td>{{ item.empresaId }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Grupo:</td>
                  <td>{{ $store.getters.getGroupNameById(item.groupId) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Monitoramento:</td>
                  <td>
                    {{ $store.getters.getMonitoramentoNameById(item.moniId) }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-black">
                    Url do software de monitoramento para ver os eventos:
                  </td>
                  <td>
                    <!-- <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click="copyUrl(item)"
                          >Copiar URL</v-btn
                        >
                      </template>
                      <span>{{ item.url }}</span>
                    </v-tooltip> -->
                    <v-row class="mt-2">
                      <v-col>
                        <v-text-field
                          v-model="item.url"
                          label="URL"
                          outlined
                          readonly
                          append-icon="mdi-content-copy"
                          @click:append="copyUrl(item)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-black">Descrição:</td>
                  <td>{{ item.description }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">
                    Usuário do software de monitoramento de imagem:
                  </td>
                  <td>{{ item.vmsUser }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">
                    Senha do software de monitoramento de imagem:
                  </td>
                  <td>{{ item.vmsPass }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Cadastrado em:</td>
                  <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Editado em:</td>
                  <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VerMonitoramento",
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      showUrl: false,
    };
  },
  methods: {
    copyUrl(item) {
      navigator.clipboard.writeText(item.url);
      this.$store.dispatch("resetSnack");
      this.$store.dispatch(
        "showSuccessSnack",
        `URL da Conta ${item.accountNumber} copiada para area de transferencia`
      );
    },
  },
};
</script>

<style>
</style>